import React, { useEffect, useState } from "react";
import Landing from "../components/Landing";
import tagManagerEvents from "../utils/GoogleTagManager";
import { Navigate } from "react-router-dom";
import { fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";

type Props = {
  token: string | null;
};

let adNetwork = "";
const LandingScreen = (props: Props) => {
  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showPlay, setShowPlay] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [confirmLoader, setConfirmLoader] = useState(false);
  const [utmSource, setUtmSource] = useState<string>("");

  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);

  const getHe = () => {
    fetch("http://gamebox.pk/partners/api/v1/digitology/he-msisdn")
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (data?.code === "0" && data?.msisdn) {
          const url = new URL(window.location.href);
          const searchParams = new URLSearchParams(url.search);
          const _utmSource: any = searchParams.get("utm_source");
          setUtmSource(_utmSource);
          getFlow(_utmSource, data.msisdn);
        } else {
          tagManagerEvents("wifiUser", utmSource);
          setShowError(true);
          setErrorMsg("Access to the Game Box available for Telenor users.");
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error("Error fetching data:", error);
        setShowError(true);
        setErrorMsg("Access to the Game Box available for Telenor users.");
      });
  };

  const getFlow = async (utmSource: string, msisdn: string | null) => {
    try {
      let data = {
        utm_source: utmSource,
        msisdn: msisdn,
      };
      let response = await fetchApiPost(props.token, ApiName.getFlow, data);
      if (response.status === 0) {
        if (
          response.response.isSpecialFlow === true &&
          response.response.msisdn !== "" &&
          response.response.otp !== ""
        ) {
          // special flow
          // const data = {
          //   isSpecialFlow: response.response.isSpecialFlow,
          //   fastFlow: response.response.fastFlow,
          //   msisdn: response.response.msisdn,
          //   otp: response.response.otp,
          //   milliseconds: response.response.milliseconds,
          //   signIn: response.response.signIn,
          //   package: response.response.package,
          //   network: response.response.network,
          //   utmSource: utmSource,
          // };
          let data_ = {
            msisdn: response.response.msisdn,
            utm_source: utmSource,
          };

          let Api_name = response.response.fastFlow
            ? ApiName.ffSubscribe
            : ApiName.subscribe;

          subscribeUser(Api_name, data_);
        } else if (
          response.response.isSpecialFlow === false &&
          response.response.msisdn
        ) {
          // 4g user without special flow
          setPhoneNo(response.response.msisdn);
          tagManagerEvents("heUser", utmSource);
          if (response.response.signIn) {
            console.log(response.response.signIn);
            window.location.replace("http://gamebox.pk/store/identify?utm_source=ads_digitology");
          }
        } else {
          // wifi user
          setShowError(true);
          setErrorMsg("Access to the Game Box available for Telenor users.");
          setErrorMsg("Access to the Game Box available for Telenor users.");
          tagManagerEvents("wifiUser", utmSource);
        }
      } else {
        setShowError(true);
        setErrorMsg("Access to the Game Box available for Telenor users.");
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg("Access to the Game Box available for Telenor users.");
      console.log("handleGetFlow Error: ", error);
    }
  };

  const onSubscribePressed = () => {
    setConfirmLoader(true);
    let data = {
      msisdn: phoneNo,
      utm_source: utmSource,
    };
    subscribeUser(ApiName.subscribe, data);
  };

  // --------------- APIs ---------------
  const subscribeUser = async (endpoint: string, data: any) => {
    try {
      setShowLoader(true);
      let response = await fetchApiPost(props.token, endpoint, data);
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          tagManagerEvents("subscribe", "tiktok");
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setConfirmLoader(false);

        setTimeout(() => {
          window.location.replace("http://gamebox.pk/store/identify?utm_source=ads_digitology");
        }, 3000);
      } else {
        tagManagerEvents("subscribeFailed", utmSource);
        setConfirmLoader(false);
        setShowLoader(false);
        setShowError(true);
        setErrorMsg("Unable to subscribe,Please try again.");
      }
    } catch (error) {
      tagManagerEvents("subscribeFailed", utmSource);
      setConfirmLoader(false);
      setShowLoader(false);
      setShowError(true);
      setErrorMsg("Unable to subscribe,Please try again.");
    }
  };
  const onHandlePlay = () => {
    setShowPlay(true);
  };
  return (
    <Landing
      showLoader={showLoader}
      errorMsg={errorMsg}
      showError={showError}
      onSubscribePressed={onSubscribePressed}
      showPlay={showPlay}
      confirmLoader={confirmLoader}
      phoneNo={phoneNo}
      onHandlePlay={onHandlePlay}
    />
  );
};

export default LandingScreen;
