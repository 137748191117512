import React from "react";
import "../styles/Landing.css";
import logo from "../Assets/imgs/gameboxlogo.webp";
import mainImg from "../Assets/imgs/main-img.webp";
import borderImg from "../Assets/imgs/border-bg.webp";
import trial from "../Assets/imgs/trial.webp";
import rsten from "../Assets/imgs/rsten.webp";
import bdrmobile from "../Assets/imgs/bdrmobile.webp";

type Props = {
  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  showPlay: boolean;
  confirmLoader: boolean;
  phoneNo: string;
  onHandlePlay: () => void;
};
const Landing = (props: Props) => {
  return (
    <>
      <div className="gameBox">
        <div className="main-content">
          <div className="gameboxlogo">
            <img src={logo} alt="logo" width={140} height={140} />
          </div>

          <div className="border__box">
            <img src={borderImg} alt="border" className="bdrdesk" />
            <img src={bdrmobile} alt="border" className="bdrmobile" />
            <div className="gameinner__box">
              <div className="gameboxcontent">
                <img
                  src={mainImg}
                  alt="main img"
                  width={400}
                  height={85}
                  className="mainImg"
                />
              </div>

              <div className="onedayfreetrail">
                <img src={trial} alt="trial" className="trial" />
                <img src={rsten} alt="trial" className="priceImg" />
              </div>
              <div className="playbtn">
                {props.showError && <p className="error">{props.errorMsg}</p>}
                {props.phoneNo && (
                  <>
                    {props.showPlay ? (
                      <>
                        {props.confirmLoader ? (
                          <button className="disable-btn">Confirm</button>
                        ) : (
                          <button onClick={() => props.onSubscribePressed()}>
                            Confirm
                          </button>
                        )}
                      </>
                    ) : (
                      <button onClick={ props.onHandlePlay}>Play</button>
                    )}
                  </>
                )}
              </div>
              <div className="text">
                <p>
                  I agree for daily recurrent charges deduction from mobile
                  account. Free trial is for new users only
                </p>
              </div>
              <div className="tc">
                <a href="/terms" target="_blank">
                  Terms & Conditions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
